import React, { useEffect, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import axios from 'axios'
import { navigate } from 'gatsby'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import jwt_decode from 'jwt-decode'

const Login = ({ location }) => {
	const classes = useStyles()

	const [username, setUsername] = useState()
	const [password, setPassword] = useState()
	const [error, setError] = useState('')

	const [showError, setShowError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')

	const submit = () => {
		axios
			.post(`${process.env.GATSBY_API_URL}/login`, {
				username,
				password
			})
			.then(res => {
				const token = res.data.token
				const { isAdmin, userName } = jwt_decode(token)
				// eslint-disable-next-line no-undef
				window.localStorage.setItem('token', token)
				// eslint-disable-next-line no-undef
				window.localStorage.setItem('isAdmin', isAdmin)
				// eslint-disable-next-line no-undef
				window.localStorage.setItem('userName', userName)
				navigate('/dashboard/')
			})
			.catch(err => {
				if (!err.response.data) {
					return
				}

				switch (err.response.status) {
					case 401:
						setError('Username and password combination are incorrect.')
						break
					default:
						setError('Unknown error occured.')
						break
				}
			})
	}

	useEffect(() => {
		// Check if auth token is already in localstorage
		// eslint-disable-next-line no-undef
		const token = window.localStorage.getItem('token')
		if (token) {
			navigate('/dashboard/')
		}

		if (location.state?.error) {
			setErrorMessage(location.state?.error)
			setShowError(true)
		}
	}, [])

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Login
				</Typography>
				<form
					className={classes.form}
					noValidate
					onSubmit={e => {
						e.preventDefault()
						submit()
					}}
				>
					<TextField
						error={error !== ''}
						helperText={error}
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="username"
						label="Username"
						name="username"
						autoFocus
						onChange={e => {
							setUsername(e.target.value)
							setError('')
						}}
					/>
					<TextField
						error={error !== ''}
						helperText={error}
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						onChange={e => {
							setPassword(e.target.value)
							setError('')
						}}
					/>
					<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
						Log In
					</Button>
				</form>
			</div>

			<Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
				<Alert onClose={() => setShowError(false)} severity="error">
					{errorMessage}
				</Alert>
			</Snackbar>
		</Container>
	)
}

export default Login

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}))
